// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  Grid,
  GridItem,
  InputText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi } from 'api-new'
// Local - Types
import { IPageSuppliersUpdateNotifications } from './types'
// Local - Table Definitions - Columns
import { columns } from './columns'

const PageSuppliersUpdateNotifications: React.FC<IPageSuppliersUpdateNotifications> = ({
  emails,
  loadData,
  supplierId
}: IPageSuppliersUpdateNotifications) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // View State - Inputs
  const [newEmail, setNewEmail] = useState<string | undefined>(undefined)
  // View State - Modals
  const [showModalNotification, setShowModalNotification] = useState<boolean>(false)

  const handleNotificationEmailRemove = (emailObject) => {
    setLoading(true)
    const apiRequest = {
      supplierID: supplierId,
      notificationEmail: emailObject.email
    }
    MainStemApi.api
      .suppliersNotificationEmailRemove(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Email removed.')
          loadData()
        }
      })
      .catch(() => {
        toast.error('Error removing email.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleNotificationEmailAdd = () => {
    setLoading(true)
    const apiRequest = {
      supplierID: supplierId,
      notificationEmail: newEmail
    }
    MainStemApi.api
      .suppliersNotificationEmailAdd(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setShowModalNotification(false)
          setNewEmail(undefined)
          toast.success('Email added.')
          loadData()
        } else {
          toast.error('Error adding email.')
        }
      })
      .catch(() => {
        toast.error('Error adding email.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Table
        actions={
          <>
            <Button
              icon={theme.icons.new}
              loading={loading}
              onClick={() => {
                setShowModalNotification(true)
              }}
            >
              Add Email
            </Button>
          </>
        }
        columns={columns(handleNotificationEmailRemove)}
        data={emails}
        loading={loading}
        title='Notification Emails'
      />
      {showModalNotification && (
        <>
          <Modal
            onClose={() => {
              setShowModalNotification(false)
            }}
          >
            <ModalHeader>Add Notification Email</ModalHeader>
            <ModalBody>
              <InputText
                label='New Email Address'
                onChange={(newValue) => {
                  setNewEmail(newValue)
                }}
                value={newEmail}
              />
            </ModalBody>
            <ModalFooter>
              <Grid cols={2} style={{ width: '100%' }}>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='danger'
                    icon={theme.icons.close}
                    onClick={() => {
                      setShowModalNotification(false)
                    }}
                  >
                    Close
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='fancy'
                    icon={theme.icons.save}
                    loading={loading}
                    onClick={() => {
                      handleNotificationEmailAdd()
                    }}
                  >
                    Save
                  </Button>
                </GridItem>
              </Grid>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export { PageSuppliersUpdateNotifications }
