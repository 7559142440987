// MainStem - UI
import { Button, theme } from '@mainstem/react-mainstem'
import { ITableColumn } from '@mainstem/react-mainstem/dist/components/Table/types'
// Bootstrap Table Filters
import { textFilter } from 'react-bootstrap-table2-filter'

export const columns = (onRemoveFn) =>
  [
    {
      dataField: 'email',
      headerText: 'Email',
      filter: textFilter()
    },
    {
      dataField: '',
      headerText: 'Action',
      formatter: (_id, data) => {
        return (
          <Button
            color='danger'
            icon={theme.icons.delete}
            onClick={() => {
              onRemoveFn(data)
            }}
          />
        )
      },
      filter: textFilter(),
      style: {
        width: 100
      }
    }
  ] as ITableColumn[]
